<template>
  <div class="header" :class="[$config.platform, { bg: background, landing, 'sidebar-expanded': active.sidebar, hidden: $route.query.preview, absolute }]">
    <div class="container">
      <div class="side-left">
        <component
          :is="logoNotLink ? 'div' : 'nuxt-link'" to="/"
          :title="$config.platformName"
          class="logo"
        >
          <component
            :is="Logo"
            class="svg-logo"
            :class="{ dark: $route.path.match(/\/signup/) && !['live-trainings-signup', 'resources-center-exams-signup'].includes($route.name) }"
          />
        </component>
        <button
          :class="{ 'is-active': active.sidebar }"
          class="hamburger hamburger--spin"
          type="button"
          @click="active.sidebar = !active.sidebar"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner" />
          </span>
        </button>
        <nav :class="{ active: active.sidebar, show: !onLogin }">
          <ul>
            <li :class="{ active: active.learn }">
              <span class="link" @click="active.learn = !active.learn">
                Learn <CaretDown v-if="$store.state.layout === 'desktop'" /><CaretDownMobile v-else />
              </span>
              <div class="submenu">
                <ul>
                  <li>
                    <nuxt-link
                      to="/courses/" class="link" title="Courses"
                      data-event="main_menu_courses.click"
                    >
                      Courses
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/career-tracks/" class="link" title="Career Tracks"
                      data-event="main_menu_career_tracks.click"
                    >
                      Career Tracks
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/projects/" class="link" title="Projects"
                    >
                      Projects
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/upcoming-courses/" class="link" title="Upcoming Courses"
                      data-event="main_menu_upcoming_courses.click"
                    >
                      Upcoming Courses
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </li>
            <li :class="{ active: active.certificates }">
              <span class="link" @click="active.certificates = !active.certificates">
                Certificates <CaretDown v-if="$store.state.layout === 'desktop'" /><CaretDownMobile v-else />
              </span>
              <div class="submenu">
                <ul>
                  <li>
                    <nuxt-link
                      to="/career-track-certificate/" class="link" title="Career Track Certificate"
                      data-event="main_menu_career_track_certificate.click"
                    >
                      Career Track Certificate
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      :to="$config.platform === 'ds' ? '/course-certificate/' : '/certificate/'" class="link" title="Course Certificate"
                      data-event="main_menu_career_course_certificate.click"
                    >
                      Course Certificate
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </li>
            <li v-if="$config.platform === 'ds'" :class="{ active: active.resources }">
              <span class="link" @click="active.resources = !active.resources">
                Resources <CaretDown v-if="$store.state.layout === 'desktop'" /><CaretDownMobile v-else />
              </span>
              <div class="submenu double">
                <ul>
                  <div class="left">
                    <li>
                      <nuxt-link
                        to="/resources-center/" class="link" title="All Resources"
                        data-event="main_menu_resources.click"
                      >
                        All Resources
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/resources-center/course-notes/" class="link" title="Course Notes"
                        data-event="main_menu_course_notes.click"
                      >
                        Course Notes
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/resources-center/templates/" class="link" title="Templates"
                        data-event="main_menu_templates.click"
                      >
                        Templates
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/resources-center/infographics/" class="link" title="Infographics"
                        data-event="main_menu_infographics.click"
                      >
                        Infographics
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/resources-center/career-guides/" class="link" title="Career Guides"
                        data-event="main_menu_career_guides.click"
                      >
                        Career Guides
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/resources-center/practice-exams/" class="link" title="Practice Exams"
                        data-event="main_menu_practice_exams.click"
                      >
                        Practice Exams
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="right">
                    <li>
                      <nuxt-link
                        to="/blog/"
                        class="link"
                        title="Blog"
                        data-event="main_menu_blog.click"
                      >
                        Blog
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/reviews/" class="link" title="Reviews"
                        data-event="main_menu_reviews.click"
                      >
                        Reviews
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/success-stories/" class="link" title="Success Stories"
                        data-event="main_menu_career_success_stories.click"
                      >
                        Success Stories
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/flashcards/" class="link" title="Flashcards"
                        data-event="main_menu_flashcards.click"
                      >
                        Flashcards
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/calculators/confidence-interval-calculator/" class="link" title="Calculators"
                        data-event="main_menu_career_success_stories.click"
                      >
                        Calculators
                      </nuxt-link>
                    </li>
                    <li>
                      <a
                        href="/interview-simulator/" class="link" title="Interview Simulator"
                        data-event="main_menu_data.click"
                      >
                        Interview Simulator
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </li>
            <li v-else-if="$config.platform === 'fa'" :class="{ active: active.resources }">
              <span class="link" @click="active.resources = !active.resources">
                Resources <CaretDown v-if="$store.state.layout === 'desktop'" /><CaretDownMobile v-else />
              </span>
              <div class="submenu double">
                <ul>
                  <div class="left">
                    <li>
                      <a
                        href="/resources-center/"
                        class="link"
                        title="All Resources"
                        data-event="main_menu_resources.click"
                        data-direct-send
                      >
                        All Resources
                      </a>
                    </li>
                    <li>
                      <a
                        href="/category/templates-and-models/"
                        class="link"
                        title="Templates and Models"
                        data-event="main_menu_templates_and_models.click"
                        data-direct-send
                      >
                        Templates
                      </a>
                    </li>
                    <li>
                      <a
                        href="/category/course-notes/"
                        class="link"
                        title="Course Notes"
                        data-event="main_menu_course_notes.click"
                        data-direct-send
                      >
                        Course Notes
                      </a>
                    </li>
                    <li>
                      <a
                        href="/category/finance-career-guides/"
                        class="link"
                        title="Career Guides"
                        data-event="main_menu_career-guides.click"
                        data-direct-send
                      >
                        Career Guides
                      </a>
                    </li>
                    <li>
                      <a
                        href="/category/cheat-sheets/"
                        class="link"
                        title="Cheat Sheets"
                        data-event="main_menu_cheat_sheets.click"
                        data-direct-send
                      >
                        Cheat Sheets
                      </a>
                    </li>
                    <li>
                      <a
                        href="/category/data/"
                        class="link"
                        title="Finance Data"
                        data-event="main_menu_data.click"
                        data-direct-send
                      >
                        Finance Data
                      </a>
                    </li>
                    <li>
                      <a
                        href="/category/infographics"
                        class="link"
                        title="Infographics"
                        data-event="main_menu_infographics.click"
                        data-direct-send
                      >
                        Infographics
                      </a>
                    </li>
                    <li>
                      <a
                        href="/category/practice"
                        class="link"
                        title="Practice Exams"
                        data-event="main_menu_practice_exams.click"
                        data-direct-send
                      >
                        Practice Exams
                      </a>
                    </li>
                  </div>
                  <div class="right">
                    <li>
                      <a
                        href="/category/knowledge-hub/"
                        class="link"
                        title="Knowledge Hub"
                        data-event="main_menu_knowledge_hub.click"
                        data-direct-send
                      >
                        Knowledge Hub
                      </a>
                    </li>
                    <li>
                      <a
                        href="/category/career-advice/"
                        class="link"
                        title="Career Advice"
                        data-event="main_menu_career-advice.click"
                        data-direct-send
                      >
                        Career Advice
                      </a>
                    </li>
                    <li>
                      <nuxt-link
                        to="/reviews/" class="link" title="Reviews"
                        data-event="main_menu_reviews.click"
                      >
                        Reviews
                      </nuxt-link>
                    </li>
                    <!-- <li>
                      <nuxt-link
                        to="/flashcards/" class="link" title="Flashcards"
                        data-event="main_menu_flashcards.click"
                      >
                        Flashcards
                      </nuxt-link>
                    </li> -->
                  </div>
                </ul>
              </div>
            </li>
            <li>
              <nuxt-link
                to="/pricing/" class="link" title="Pricing"
                data-event="main_menu_pricing.click"
              >
                Pricing
              </nuxt-link>
            </li>
            <li v-if="$config.platform === 'ds'" :class="{ active: active.business }">
              <span class="link business" @click="active.business = !active.business">
                For Business <CaretDown v-if="$store.state.layout === 'desktop'" /><CaretDownMobile v-else />
              </span>
              <div class="submenu">
                <ul>
                  <li>
                    <nuxt-link
                      to="/business/" class="link" title="Team Plan"
                      data-event="main_menu_business.click"
                    >
                      Team Plan
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/live-trainings/" class="link" title="Live Training"
                      data-event="main_menu_live_trainings.click"
                    >
                      Live Training
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </li>
            <li v-else :class="{ active: active.business }">
              <span class="link business" @click="active.business = !active.business">
                <nuxt-link
                  to="/business/" class="link business fa" title="Business"
                  data-event="main_menu_business.click"
                >
                  For Business
                </nuxt-link>
              </span>
            </li>
            <li v-if="$store.state.auth.user" class="button" :class="{ 'show-on-mobile': !onCheckout }">
              <a :href="$config.lmsUrl" class="link">
                <span class="btn-primary">
                  My Learning
                </span>
              </a>
            </li>
            <template v-else>
              <li :class="{ 'show-on-mobile': !onCheckout }">
                <nuxt-link
                  to="/login/" class="link" title="Login"
                  data-event="log_in.click"
                >
                  Login
                </nuxt-link>
              </li>
              <li class="button" :class="{ 'show-on-mobile': !onCheckout }">
                <nuxt-link
                  to="/signup/" class="link" title="Sign Up"
                  data-event="sign_up.click"
                >
                  <span class="btn-primary">
                    Sign Up
                  </span>
                </nuxt-link>
              </li>
            </template>
          </ul>
        </nav>
      </div>
      <div v-if="!['/business/signup/', '/instructors/signup/'].includes($route.path)" class="side-right">
        <template v-if="['/login/', '/forgotten-password/', '/password/', '/signup/', '/instructors/signup/', '/warning/'].includes($route.path)">
          <div v-if="['/login/','/forgotten-password/','/password/', '/warning/'].includes($route.path)">
            <span>Don’t have an account?</span>
            <nuxt-link
              :to="`/signup/${params}`" class="signup btn-primary" title="Sign Up"
              data-event="sign_up.click"
            >
              Sign Up
            </nuxt-link>
          </div>
          <div v-else>
            <span>Already have an account?</span>
            <nuxt-link
              :to="`/login/${params}`" class="signup btn-primary" title="Log In"
              data-event="log_in.click"
            >
              Log In
            </nuxt-link>
          </div>
        </template>
        <template v-else-if="$route.name === 'courses-landing-course'">
          <nuxt-link to="/checkout/" class="btn-primary" title="Buy Course $9.95">
            Buy Course $9.95
          </nuxt-link>
        </template>
        <template v-else-if="blogPage">
          <SearchBarExpand v-if="blogPage" class="searchbar" />
          <nuxt-link
            v-if="!$store.state.auth.user && blogPage"
            to="/login/"
            class="login link"
            title="Log In"
            data-event="log_in.click"
          >
            Log In
          </nuxt-link>
          <a v-if="$store.state.auth.user" :href="$config.lmsUrl" class="btn-primary">
            My Learning
          </a>
          <nuxt-link
            v-else to="/signup/" class="signup btn-primary"
            title="Sign Up"
            data-event="sign_up.click"
          >
            Sign Up
          </nuxt-link>
        </template>
        <template v-else>
          <a v-if="$store.state.auth.user" :href="$store.state.auth.user.role === 'business-admin' ? `${$config.lmsUrl}/business` : $config.lmsUrl" class="btn-primary">
            My Learning
          </a>
          <template v-else>
            <nuxt-link
              to="/login/" class="login link" title="Log In"
              data-event="log_in.click"
            >
              Log In
            </nuxt-link>
            <nuxt-link
              to="/signup/" class="signup btn-primary" title="Sign Up"
              data-event="sign_up.click"
            >
              Sign Up
            </nuxt-link>
          </template>
        </template>
      </div>
    </div>
    <nuxt-link v-if="smallV3Banner && !$store.getters['banners/hasPromoBanner']" to="/news/add-fun-to-data-science-learning/" class="small-banner-v3">
      <span>The 365 Learning Platform is now <b>gamified</b> and has a fresh, new look! Check out our latest <b>blog post</b> to learn more!</span>
    </nuxt-link>
  </div>
</template>

<script>
import SearchBarExpand from '../components/Blog/Search/SearchBarExpand';
import Logo from '../assets/svg/365-logo.svg?vue-component';
import CaretDown from '../assets/svg/caret-down.svg?vue-component';
import CaretDownMobile from '../assets/svg/caret-down-mobile.svg?vue-component';

import {
  BANNER_FORBIDDEN_ROUTES, LANDING_PAGES, SELLABLE_COURSES, UPSELL_PAGES, AFFILIATE_PAGES, DISCOVER_PAGES, CAMPAIGN_PAGES,
} from '../utils/constants';

// const forbiddenSmallV3BannerRoutes = [
//   ...AFFILIATE_PAGES,
//   ...LANDING_PAGES,
//   ...CAMPAIGN_PAGES,
//   '/pricing/',
//   '/checkout/',
// ];

export default {
  components: {
    CaretDown, CaretDownMobile, SearchBarExpand, Logo,
  },
  data() {
    return {
      currentPath: '/',
      fixed: false,
      currentPlanPrice: null,
      smallV3Banner: false,
      active: {
        sidebar: false,
        learn: false,
        certificates: false,
        resources: false,
        business: false,
      },
      resourceCategories: [],
    };
  },
  computed: {
    logoNotLink() {
      return [...CAMPAIGN_PAGES, '/redirecting/'].includes(this.$route.path);
    },
    onLogin() {
      return ['/login/', '/signup/', '/instructors/signup/', '/business/signup/', '/forgotten-password/', '/password/', '/warning/', '/resources-center/preview/', '/redirecting/'].includes(this.$route.path);
    },
    blogPage() {
      return this.$route.name === 'blog' || ['/career-advice/', '/tutorials/', '/trending/', '/news/', '/meet-the-team/'].some((v) => this.$route.path.includes(v));
    },
    Logo() {
      if (this.$config.platform === 'fa') {
        return () => import('../assets/svg/365-fa-logo-extended.svg?vue-component');
      }
      return () => import('../assets/svg/365-logo-extended.svg?vue-component');
    },
    absolute() {
      // Used for static header without scrolling down
      return ['cfa-level-1-exam-preparation', 'cfa-level-2-exam-prep'].includes(this.$route.name.toLowerCase());
    },
    background() {
      const pagesWithBackground = [
        'blogCategory-blogSubItem',
        'blogCategory-blogSubItem-blogArticle',
        'question-question',
        'preview-id',
        'live-trainings',
        'calculators-name',
        'calculators-tables-id',
        'sitemap',
        'f-id',
        'courses-course-lecture',
      ].includes(this.$route.name);

      const onMobileLoginOrSignup = ['login', 'signup', 'forgotten-password', '/business/signup/'].includes(this.$route.name) && this.$store.state.layout === 'mobile';

      const monthlyPlanExtend = this.$store.getters['auth/user'].activeSubscriptionPlan === 0 && this.$store.getters['subscriptions/monthly'].discountPrice < this.currentPlanPrice;
      const quarterlyPlanExtend = this.$store.getters['auth/user'].activeSubscriptionPlan === 1 && this.$store.getters['subscriptions/quarterly'].discountPrice < this.currentPlanPrice;
      const annualPlanDiscountExtend = this.$store.getters['auth/user'].activeSubscriptionPlan === 2 && this.$store.getters['subscriptions/annual'].discountPrice < this.currentPlanPrice;
      const annualExtend = monthlyPlanExtend || quarterlyPlanExtend || annualPlanDiscountExtend;

      /**
       * replace (this.$store.getters['banners/promoBanner'] && !this.$store.getters['banners/closedByUser'])
       * with
       * this.$store.getters['banners/hasPromoBanner']
       */
      const output = (this.smallV3Banner && !this.$store.getters['subscriptions/hasPromoBanner']) || pagesWithBackground || this.$route.name.includes('flashcards') || onMobileLoginOrSignup || (this.$store.getters['subscriptions/promoBanner']
              && !BANNER_FORBIDDEN_ROUTES.includes(this.$route.path) && !this.$store.getters['subscriptions/bannerClosedByUser']
              && annualExtend && this.$store.getters['auth/user'].role !== 'business-student' && this.$store.getters['auth/user'].role !== 'business-admin');

      return this.fixed || output;
    },
    landing() {
      const landingPages = [
        ...LANDING_PAGES,
        ...UPSELL_PAGES,
        ...(this.$route.path !== '/ty-downloadable-resources/' ? DISCOVER_PAGES : []),
        ...CAMPAIGN_PAGES,
        ...AFFILIATE_PAGES,
        ...SELLABLE_COURSES,
        '/checkout/',
        '/live-trainings/signup/',
        '/redirecting/',
      ].includes(this.$route.path);

      return landingPages;
    },
    onCheckout() {
      return AFFILIATE_PAGES.includes(this.$route.path) || SELLABLE_COURSES.includes(this.$route.path) || this.$route.path === '/checkout/' || this.$route.path === '/live-trainings/signup/';
    },
    params() {
      return this.$route.fullPath.replace(this.$route.path, '') || '';
    },
  },
  watch: {
    $route(v) {
      // this.smallV3Banner = !forbiddenSmallV3BannerRoutes.includes(v.path);
      if (this.currentPath !== v.path && this.$store.state.layout === 'mobile') {
        this.active.sidebar = false;
      }
      this.currentPath = v.path;
    },
  },
  async created() {
    if (process.client) {
      if (this.$store.getters['auth/user'].id && this.$store.getters['banners/promoBanner']) {
        this.currentPlanPrice = this.$store.getters['subscriptions/active'].priceWoutTaxes;
      }

      window.addEventListener('scroll', () => {
        this.checkHeaderPosition();
      });
    }
    this.currentPath = this.$route.path;
  },
  async mounted() {
    this.checkHeaderPosition();

    this.$root.$on('emit-click', (e) => {
      if (this.active.sidebar && this.$el.querySelector('nav') && this.$el.querySelector('nav').classList.contains('active')
          && (e.target.tagName === 'A' || e.target.classList.contains('svg-logo'))) {
        this.active.sidebar = !this.active.sidebar;
      }
    });
  },
  methods: {
    checkHeaderPosition() {
      this.fixed = (window.pageYOffset || document.documentElement.scrollTop) > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  transition: background-color var(--duration) ease;
  color: var(--main-light);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;

  &.absolute {
    position: absolute;
  }

  &.landing {
    background-color: transparent;

    .container {
      .side-left {
        button,
        nav {
          display: none;
        }
      }

      .side-right {
        display: none;
      }
    }
  }

  &.bg {
    background-color: var(--main-dark);
  }

  .hamburger {
    display: none;
  }

  .logo {
    display: flex;

    svg {
      width: 200px;
      height: 24px;
      margin-bottom: 10px;
    }

    svg.dark {
      path {
        fill: var(--main-dark);
      }
    }
  }

  .container {
    width: auto;
    padding: 0 var(--spacing-40);
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .side-left {
      display: flex;
      align-items: center;

      nav {
        margin-left: var(--spacing-20);

        &:not(.show) {
          display: none;
        }

        > ul {
          display: flex;
          list-style: none;
          padding: 0;
          margin: 0;

          > li {
            position: relative;
            z-index: 0;

            &.show-on-mobile {
              display: none;
            }

            > a,
            > .link {
              display: flex;
              align-items: center;
              padding: var(--spacing-6) var(--spacing-16);
              border-radius: 3px;

              &.business {
                color: var(--main-yellow);

                &:hover {
                  color: var(--main-yellow-dark-4);

                  svg path[stroke] {
                    stroke: var(--main-yellow-dark-4);
                  }
                }

                svg path[stroke] {
                  stroke: var(--main-yellow);
                }
              }

              .fa {
                color: var(--main-yellow);
                padding: 0;

                &:hover {
                  color: var(--main-yellow-dark-4);
                }
              }

              svg {
                margin-left: var(--spacing-8);

                path {
                  transition: all var(--duration) ease-in;
                }
              }

              &:active {
                svg {
                  path {
                    transition-duration: 0ms;

                    &[fill] {
                      fill: var(--main-light-shade-2);
                    }

                    &[stroke] {
                      stroke: var(--main-light-shade-2);
                    }
                  }
                }
              }
            }

            > span.link {
              cursor: default;
              transition: all var(--duration) ease-in;

              &:active {
                color: var(--main-light-shade-2);
                transition-duration: 0ms;
              }
            }

            .submenu {
              visibility: hidden;
              opacity: 0;
              position: absolute;
              top: 50%;
              padding-top: var(--spacing-8);
              transition: all var(--duration) ease-in;

              ul {
                background-color: var(--main-dark-shade-2);
                border-radius: 3px;
                list-style: none;
                padding: var(--spacing-24) var(--spacing-36) var(--spacing-24) var(--spacing-16);
                box-shadow: 0 10px 20px rgba(0, 12, 31, 0.2);

                li {
                  a {
                    white-space: nowrap;
                  }

                  + li {
                    margin-top: var(--spacing-24);
                  }
                }
              }

              &.double {
                ul {
                  display: flex;

                  .left {
                    padding-right: var(--spacing-18);
                    border-right: 1px solid var(--main-dark-shade-3);
                  }

                  .right {
                    padding-left: var(--spacing-18);
                  }
                }
              }
            }
          }
        }
      }
    }

    .side-right {
      display: flex;
      align-items: center;

      .searchbar {
        list-style: none;
        margin-right: var(--spacing-32);
        display: flex;
        align-items: center;
        position: relative;
        height: 52px;

        &:after {
          margin-left: -10px;
        }

        a {
          display: flex;
          position: relative;
          left: 0;
          transition: all var(--duration), width 600ms ease;

          &.active {
            left: -16px;
          }
        }
      }

      span {
        margin-right: 24px;
      }

      .login {
        margin-right: var(--spacing-12);
        padding: var(--spacing-6) var(--spacing-16);
        border-radius: 3px;
        font-weight: 500;
      }

      .signup {
        font-size: 16px;
        padding: 0.7em 1.3em;
      }
    }
  }

  &.hidden {
    display: none;
  }

  &.fa {
    font-family: 'Mazzard', sans-serif;
  }
}

.small-banner-v3 {
  position: relative;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 10px;
  background-color: var(--main-purple);
  color: var(--main-light);
  z-index: -2;
}

@include bp($bp-desktop) {
  .header .container .side-left nav > ul > li {
    > a,
    > .link {
      &:hover {
        svg {
          path[fill] {
            fill: var(--main-light-shade-4);
          }

          path[stroke] {
            stroke: var(--main-light-shade-4);
          }
        }
      }
    }

    span.link:hover {
      color: var(--main-light-shade-4);
    }

    &:hover {
      z-index: 1;

      .submenu {
        opacity: 1;
        visibility: visible;
        top: 100%;
      }
    }
  }
}

@include bp($bp-laptop) {
  .header {
    .container {
      padding: 0 var(--spacing-20);

      .side-left nav > ul > li {
        .link {
          padding: var(--spacing-6) 0;
          margin-right: var(--spacing-24);
        }

        &:last-of-type .link {
          margin: 0;
        }
      }

      .side-right {
        .signup {
          padding: 0.6em;
        }

        .login {
          padding: 0;
        }
      }
    }
  }
}

@include bp($bp-mobile) {
  .header {
    &.sidebar-expanded .container {
      background-color: var(--main-dark);
    }

    .container {
      height: 68px;
      padding: 0 var(--spacing-24);

      .side-left {
        width: 100%;
        justify-content: space-between;

        .logo svg {
          margin-top: 8px;
        }

        nav {
          position: fixed;
          top: 0;
          right: -100%;
          background-color: var(--main-dark);
          width: 100%;
          height: 100%;
          padding-top: 68px;
          overflow-y: auto;
          transition: right var(--duration) ease-in;
          margin: 0;
          z-index: -1;

          &:not(.show) {
            display: block;
          }

          &.active {
            right: 0;
          }

          > ul {
            display: block;
            padding-top: var(--spacing-18);

            > li {
              font-size: 28px;
              line-height: 28px;

              &.show-on-mobile {
                display: block;
              }

              a,
              .link {
                padding-left: 24px;
                padding-right: 24px;
              }

              &.button {
                margin: var(--spacing-80) 0;

                .btn-primary {
                  width: 100%;
                  max-width: 350px;
                }
              }

              + li {
                margin-top: 36px;
              }

              svg {
                transition: all var(--duration) ease-in;
              }

              .submenu {
                position: static;
                overflow: hidden;
                font-size: 21px;
                line-height: 21px;
                max-height: 0;

                ul {
                  background: none;
                }

                &.double {
                  ul {
                    flex-direction: column;

                    .left {
                      padding-right: 0;
                      border-right: 0;
                      border-bottom: 1px solid var(--main-dark-shade-3);
                      padding-bottom: var(--spacing-18);
                    }

                    .right {
                      padding-left: 0;
                      padding-top: var(--spacing-18);
                    }
                  }
                }
              }

              &.active {
                span.link:not(.business) {
                  color: var(--main-light-shade-4);
                }

                svg {
                  transform: rotate(180deg);

                  path[fill] {
                    fill: var(--main-light-shade-4);
                  }

                  path[stroke] {
                    stroke: var(--main-light-shade-4);
                  }
                }

                .submenu {
                  max-height: 220px;
                  visibility: visible;
                  opacity: 1;

                  &.double {
                    max-height: 1000px;
                  }
                }
              }
            }
          }
        }

        .hamburger {
          display: block;
          padding: 10px 0 10px 10px;

          &:hover,
          &.is-active:hover {
            opacity: 1;
          }

          .hamburger-box {
            width: 20px;
            height: 14px;
          }

          .hamburger-inner,
          .hamburger-inner:before,
          .hamburger-inner:after {
            background-color: var(--main-light);
            width: 20px;
            height: 2px;
            border-radius: 5px;
            right: 0;
          }

          .hamburger-inner:before {
            top: -6px;
            width: 26px;
          }

          .hamburger-inner:after {
            bottom: -6px;
            width: 24px;
          }

          &.is-active .hamburger-inner,
          &.is-active .hamburger-inner:before,
          &.is-active .hamburger-inner:after {
            background-color: var(--main-light);
            width: 20px;
          }

          &.is-active .hamburger-inner:after {
            bottom: 0;
          }
        }
      }

      .side-right {
        display: none;
      }
    }

    .logo {
      svg.dark {
        path {
          fill: var(--main-light);
        }
      }
    }

    &.fa {
      .container .side-left .logo svg {
        margin: 0;
      }

      .side-left .hamburger {
        padding-bottom: var(--spacing-4);
      }
    }
  }

  .small-banner-v3 {
    padding: 5px 10px;
  }
}

.fa.header .logo svg {
  margin-bottom: 0;
}
</style>
